<template>
  <div class="main-block finish">
    <div class="main-block-content">

      <div class="main-block-title">Personal information</div>

      <div class="grid-column-3" v-if="wallet" data-test="wallet">

        <form-group data-test="wallet_first_name" label="First Name" :value="wallet.first_name" :errors="walletErrors.first_name">
          {{ wallet.first_name }}
        </form-group>

        <form-group data-test="wallet_last_name" label="Last Name" :value="wallet.last_name" :errors="walletErrors.last_name">
          {{ wallet.last_name }}
        </form-group>

      </div>

      <hr>

      <div class="main-block-title">Address details</div>

      <div class="grid-column-3" v-if="wallet" data-test="wallet-address">

        <form-group data-test="wallet_country" label="Country" :value="getCountryById(wallet.country_id)" :errors="walletErrors.country_id">
          {{ getCountryById(wallet.country_id) }}
        </form-group>

        <form-group data-test="wallet_city" label="City" :value="wallet.city" :errors="walletErrors.city">
          {{ wallet.city }}
        </form-group>

        <form-group data-test="wallet_street" label="Street" :value="wallet.street" :errors="walletErrors.street">
          {{ wallet.street }}
        </form-group>

        <form-group data-test="wallet_house" label="House" :value="wallet.house" :errors="walletErrors.house">
          {{ wallet.house }}
        </form-group>

        <form-group data-test="wallet_apartment" label="Apartment" :value="wallet.apartment" :errors="walletErrors.apartment">
          {{ wallet.apartment }}
        </form-group>

        <form-group data-test="wallet_postal_code" label="Postal Code" :value="wallet.postal_code" :errors="walletErrors.postal_code">
          {{ wallet.postal_code }}
        </form-group>

      </div>

      <hr>

      <div class="main-block-title">Address confirmation files</div>

      <div v-if="walletAddressFiles.length" class="grid-column-3">
        <form-group v-for="file in walletAddressFiles" :value="file.name" :key="file.name" :label="getAddressTypeByID(file.type)" data-test="walletAddressFiles">
          <a target="_blank" :href="file.url" v-if="file.name">
            <icon-attach color="#19191C" class="file-attach"/>
            <span>{{ file.original_name }}</span>
          </a>
        </form-group>

        <form-group v-if="walletErrors.address_confirmation_files" :errors="walletErrors.address_confirmation_files"></form-group>
      </div>
      <div v-else class="grid-column-3-1">
        <div class="custom-error">No address confirmation files added</div>
      </div>

      <hr>

      <div class="main-block-title">Identity documents</div>

      <div v-if="walletIdentityFiles.length" class="grid-column-3">
        <form-group v-for="file in walletIdentityFiles" :value="file.name" :key="file.name" :label="getIdentityTypeByID(file.type)" data-test="walletIdentityFiles">
          <a target="_blank" :href="file.url" v-if="file.name">
            <icon-attach color="#19191C" class="file-attach"/>
            <span>{{ file.original_name }}</span>
          </a>
        </form-group>

        <form-group v-if="walletErrors.id_document_files" :errors="walletErrors.id_document_files"></form-group>
      </div>
      <div v-else class="grid-column-3-1">
        <div class="custom-error">No identity documents added</div>
      </div>

      <hr>

      <div class="main-block-title">PEP/FATCA</div>

      <div class="grid-column-3" v-if="wallet">
        <form-group data-test="person_is_pep" label="Does the Person qualify as a PEP?" :value="getBoolById(wallet.is_pep)" :errors="walletErrors.is_pep">
          {{ getBoolById(wallet.is_pep) }}
        </form-group>

        <form-group data-test="person_is_us" label="Does the Person qualify as a U.S. person?" :value="getBoolById(wallet.is_us)" :errors="walletErrors.is_us">
          {{ getBoolById(wallet.is_us) }}
        </form-group>
      </div>

      <hr>

      <div class="form-buttons-finish">
        <div>

          <label class="form-control-custom" style="margin-bottom: 0; font-size: 12px;">
            <input type="checkbox" v-model="form.is_agree" :value="1" data-test="agree">
            <span class="form-control">I agree with <a :href="links.terms_and_conditions" target="_blank" class="bold underline">Terms & Conditions</a></span>
          </label>

        </div>
        <div>

          <button class="btn-next" @click="submit" :disabled="canSave" data-test="submit">
            <span>Finish</span>
            <icon-next color="#19191C" size="15"/>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup";
import IconNext from "@/assets/icons/IconNext";
import {mapState} from "vuex";
import IconAttach from "@/assets/icons/IconAttach";

export default {
  name: 'IndividualFinish',
  components: {
    FormGroup,
    IconNext,
    IconAttach
  },
  data () {
    return {
      form: {
        is_agree: null
      }
    }
  },
  computed: {
    ...mapState({
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data,

      links: state => state.init.links,

      // Default list data
      identityTypeList: state => state.init.companyIndividualIDDocumentFileType,
      addressTypeList: state => state.init.companyIndividualAddressConfirmationFileType,
      countries: state => state.init.countries,
      boolAnswers: state => state.init.boolAnswers,
    }),

    mdl () {
      return this.registrationData['company-wallet']
    },

    walletErrors () {
      return this.mdl.errors
    },
    wallet () {
      return this.mdl.attributes
    },
    walletAddressFiles () {
      return this.mdl.attributes.address_confirmation_files
    },
    walletIdentityFiles () {
      return this.mdl.attributes.id_document_files
    },
    canSave () {
      return !this.form.is_agree
    },
  },
  methods: {
    getAddressTypeByID(id) {
      let item = this.addressTypeList.find(i => i.id === id)
      return item ? item.label : ''
    },
    getIdentityTypeByID(id) {
      let item = this.identityTypeList.find(i => i.id === id)
      return item ? item.label : ''
    },
    getCountryById(id) {
      let item = this.countries.find(i => i.id === id)
      return item ? item.label : ''
    },
    getBoolById(id) {
      let item = this.boolAnswers.find(i => i.id === id)
      return item ? item.label : ''
    },

    submit () {
      this.$sdk.registration.finish(this.registrationHash,{agree: true}).then((response) => {
        if (Array.isArray(response.data) && response.data.length === 0) {
          this.$emit('next-step')

          setTimeout(() => {
            this.$store.dispatch('resetRegistration')
          }, 200)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.custom-error {
  content: 'Not set';
  color: #EA4C17;
  display: inline-block;
  font-size: 13px;
}

.form-group:not(.has-value) {
  &:after {
    .custom-error
  }
}

.finish {
  .form-group {
    line-height: 1.65em;
  }

  a {
    color: #19191C;
    vertical-align: middle;

    span {
      text-decoration: underline;
    }

    &:hover span {
      text-decoration: none;
    }
    &:visited {
      color: #19191C;
    }
  }
  .file-attach {
    margin-right: 5px;
    position: relative;
    bottom: -4px;
  }
}
</style>
